import React from "react";
import '../../assets/styles/header.css';
import logo from '../../assets/img/logo.jpg';
import {useAppSelector} from "../../app/hooks";

const HeaderComponent: React.FC = () => {
    const offer = useAppSelector(state => state.objects.objects[0]);
    const agency = offer ? offer.values['67'].value[0] : '';
    const agencyLogo = agency ? agency.recordValues['9'][0].url : '';
    const agencyPhone = agency ? agency.recordValues['3'][0].contact : '';
    const agencyName = agency ? agency.recordValues['2'] : '';
    const proposal = useAppSelector(state => state.objects.proposal);

    // logo selected https://www.selected-estate.com/images/selected-logo.png
    if (proposal.length > 0) {
        return (
            <header className="header">
                <div className="header__container">
                    <div className="header-logo" style={{padding: '5px 0'}}>
                        <img src={agencyLogo ? agencyLogo : logo} alt="logo"/>
                    </div>
                    <div className="header-agency-name">
                        {agencyName}
                    </div>
                    <div className="header-phone">
                        <span>{proposal[0].values[7].length > 0 && proposal[0].values[7][0].recordValues[17][0].contact}</span>
                    </div>
                </div>
            </header>
        )
    } else {
       return  <div>Loading</div>
    }
}

export default HeaderComponent