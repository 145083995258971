import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useAppDispatch } from '../../../app/hooks';
import MapCatalog from './MapCatalog';
import { useParams } from 'react-router-dom';
import { setSelection } from '../../../app/SettingSlice';
import "../../../assets/styles/search-block.css";
import SearchInput from "../../search-block/SearchInput";

const Main: React.FC = () => {
    const dispatch = useAppDispatch();
    const { selectId } = useParams();

    useEffect(() => {
        if (selectId) {
            dispatch(setSelection(selectId))
        } else {
            dispatch(setSelection('2'))
        }
    }, [dispatch, selectId])


    return (
        <Layout className='catalog'>
            <SearchInput />
            <div className='catalog-right catalog-right-visibility' id='catalog-right'>
                <MapCatalog/>
            </div>
        </Layout>
    )
}

export default Main;