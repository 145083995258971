import mess1 from '../../assets/icons/footer/messengers/svg33.svg';
import mess2 from '../../assets/icons/footer/messengers/svg34.svg';
import mess3 from '../../assets/icons/footer/messengers/svg35.svg';
import mess4 from '../../assets/icons/footer/messengers/svg36.svg';
import mess5 from '../../assets/icons/footer/messengers/svg37.svg';
import mess6 from '../../assets/icons/footer/messengers/svg38.svg';
import mess7 from '../../assets/icons/footer/messengers/svg39.svg';
import mess8 from '../../assets/icons/footer/messengers/svg40.svg';
import mess9 from '../../assets/icons/footer/messengers/svg41.svg';
import mess10 from '../../assets/icons/footer/messengers/svg42.svg';

import card1 from '../../assets/icons/footer/cards/svg43.png';
import card2 from '../../assets/icons/footer/cards/svg44.png';
import card3 from '../../assets/icons/footer/cards/svg45.png';
import card4 from '../../assets/icons/footer/cards/svg46.png';
//import card5 from '../../assets/icons/footer/cards/svg47.png';
import card6 from '../../assets/icons/footer/cards/svg48.png';
import card7 from '../../assets/icons/footer/cards/svg49.png';
import card8 from '../../assets/icons/footer/cards/svg50.png';
import card9 from '../../assets/icons/footer/cards/svg51.png';
import card10 from '../../assets/icons/footer/cards/svg52.png';

export const messengers = [ mess1, mess2, mess3, mess4, mess5, mess6, mess7, mess8, mess9, mess10 ];
export const cards = [ card1, card2, card3, card4, card6, card7, card8, card9, card10 ];