import React, {useEffect, useState} from 'react';
import './App.css';
import HeaderComponent from './components/header/Header';
import Main from './components/main/teasers/Main';
//import { Route, Routes } from 'react-router-dom';
import { Routes, Route, Navigate } from 'react-router-dom';
import OfferPage from './components/main/offers/OfferPage';
import { ConfigProvider, Layout } from 'antd';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { PropertyData } from './types';
import { setGeoToObj, setObject, setProposal } from './app/ObjectSlice';


const App: React.FC = () => {
  
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const selectId = useAppSelector(state => state.setting.selection)
  
  useEffect(() => {
    if (selectId) {

      setLoading(true);
      axios
      .get(`https://us-central1-aisales-bot-vieoxe.cloudfunctions.net/property-api?id=${selectId}`, {
          headers: {
              "Content-type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
  
            }
      })
      .then((resp) => {
          const dataresp: PropertyData = resp.data;
          dispatch(setObject(dataresp.Properties));
          dispatch(setGeoToObj(dataresp.Properties));
          dispatch(setProposal(dataresp.Proposal));
          setLoading(false);
      })
      .catch((error) => console.error(error)) 
    }
  }, [dispatch, selectId])

  return (
    <ConfigProvider
      theme={{
        token: {   
          //fontFamily: 'Dancing Script, Montserrat, Ubuntu, sans-serif',
            fontFamily: 'Open Sans, Montserrat, Ubuntu, sans-serif',
        }
      }}>
        {!loading ? 
          <Layout>
            <HeaderComponent/>
            <main className='wrapper'>
              <Routes>
                  <Route path='selection/:selectId' element={<Main/>}/>
                  <Route path='selection/:selectId/offers/:id' element={<OfferPage/>}/>            
                  <Route path="*" element={<Navigate to="/selection/2" replace />} />
              </Routes>
            </main>
          </Layout>
        : 
        <div style={{textAlign: 'center'}}>
          Идет загрузка
        </div>      
        }
        
      </ConfigProvider>  
  );
}

export default App;
