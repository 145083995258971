import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';

interface ContactButtonsProps {
    selectId: string;
    objId: string;
}
  

const ContactButtons: React.FC<ContactButtonsProps> = ({selectId, objId}) => {
    const [like, setLike] = useState(false);
    const [dislike, setDislike] = useState(false);
    const [isOpenModal, setIsModalOpen] = useState(false);
    const [comment, setComment] = useState('');

    const url = 'https://us-central1-aisales-bot-vieoxe.cloudfunctions.net/property-like';

    function sendFeedback(feedbackData: {like: boolean, comment: string }) {
        const feedbackBody = {
            selectionId: selectId,
            objectId: objId,
            like: feedbackData.like,
            comment: feedbackData.comment
        }
        axios.post(url, feedbackBody)
          .then(response => {
            console.log('Feedback sent successfully:', response.data);
            setComment('');
          })
          .catch(error => {
            console.log('error', feedbackBody)
            console.error('Error sending feedback:', error);
            setComment('');
          });
      }    

    function handlerLike(e: React.MouseEvent<HTMLSpanElement>) {
        e.preventDefault();
        setLike(!like);
        if (dislike) {
            setDislike(false);
        }
        setIsModalOpen(true);
    }

    function handlerDislike(e: React.MouseEvent<HTMLSpanElement>) {
        e.preventDefault();
        setDislike(!dislike);
        if (like) {
            setLike(false)
        }
        setIsModalOpen(true);
    }


    const handleOk = () => {
        if (like) {
            sendFeedback({like: true, comment: comment });
        }
        if (dislike) {
            sendFeedback({like: false, comment: comment });
        }
        setIsModalOpen(false);
    };
    
    const handleCancel = () => {
        if (like) {
            sendFeedback({like: true, comment: comment });
        }
        if (dislike) {
            sendFeedback({like: false, comment: comment });
        }
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className="object-contact">
                    <div className="btn" onClick={(e) => {handlerLike(e)}}>
                        {like ? <LikeFilled /> :
                        <LikeOutlined />}
                        Подходит
                    </div>
                    <div className="btn btn-not" onClick={(e) => {handlerDislike(e)}}>
                        {dislike ? <DislikeFilled /> :
                        <DislikeOutlined />}
                        Не подходит
                    </div>
                </div>
                <Modal title='' open={isOpenModal} onOk={handleOk} onCancel={handleCancel}>
                    <div className="modal-feedback">
                        <p>Ваш комментарий поможет нам подобрать для вас лучший вариант</p>
                        <textarea 
                            placeholder="Введите сообщение..." 
                            value={comment}
                            cols={25} rows={5}
                            onChange={(e) => setComment(e.target.value)}
                            >
                        </textarea>
                    </div>
                </Modal>
        </div>
    )
}

export default ContactButtons;